import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { patientService } from "../../services/patientServices";
import { isValidEmail } from "../../helper/helper";
import { showNotification } from "../../helper/helper";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../store/LoaderSlice";
import { setdocpopup } from "../../store/DocLoginSlice";
import Timer from "../timerComponent/Timer";
import { setLogin } from "../../store/LoginPopupSlice";

const PatientLoginModal = ({
  togglePopup,
  settogglePopup,
  email,
  setemail,
  settoken,
  setotp,
  settoggleEmailVerify,
  setuser,
  toggleEmailVerifyRegister,
  settoggleEmailVerifyRegister,
  setshowDocLogin,
}) => {
  const dispatch = useDispatch();
  function handleClose() {
    settogglePopup(false);
    dispatch(setLogin(false));
  }

  async function handleLoginRegister(e) {
    e.preventDefault();
    if (email) {
      settogglePopup(false);
      dispatch(loader(true));
      const result = await patientService.login(email);
      // console.log("result ", result);

      if (result.msg === "Your account is deleted or blocked by admin") {
        showNotification(
          "error",
          "Acount Deleted or Blocked",
          "Your account is deleted or blocked by admin. Contact support"
        );
      } else {
        if (result.email_verified == true) {
          settoken(result.token);
          //remove in production
          // toast(`Your Otp ${result.otp}`);
          setotp(result.otp);
          setuser(result.user);
          dispatch(loader(false));
          settoggleEmailVerify(true);
        }
        if (result.email_verified == false) {
          // console.log("else hit");
          dispatch(loader(false));
          setotp(result.otp);
          //remove in production
          // toast(`Your Otp ${result.otp}`);

          settogglePopup(false);
          setTimeout(() => {
            showNotification(
              "warning",
              "You are not registered",
              "An otp is sent on the email for verification."
            );
          }, 400);
          settoggleEmailVerifyRegister(true);
        }
      }

      dispatch(loader(false));
    }
  }

  async function changePopup() {
    handleClose();
    setshowDocLogin(true);
    // dispatch(setdocpopup(true));
  }
  return (
    <Modal
      show={togglePopup}
      onHide={handleClose}
      id="PatientLoginModal"
      backdrop="static"
      dialogClassName="modal-dialog"
      centered
    >
      <div className="modal-content">
        <div className="modal-body">
          <button
            type="button"
            className="CloseModal"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            ×
          </button>
          <div className="LoginBox">
            <div className="LoginHead">
              <img src="images/Fav.png" alt="Logo" />
            </div>
            <div className="LoginBody">
              <article>
                <h3>Login to Your Account</h3>
              </article>
              <Form onSubmit={handleLoginRegister}>
                <Form.Group>
                  <Form.Control
                    type="email"
                    className="mb-3"
                    placeholder="Enter Email Address"
                    onChange={(e) => setemail(e.target.value)}
                    required
                  />
                </Form.Group>
                <div className="ButtonBox">
                  <Button variant="dark" id="Login" type="submit">
                    Sign In / Sign Up
                  </Button>
                </div>
              </Form>
              <aside>
                <h6>
                  Are you expert care?{" "}
                  <a href="javascript:void(0);" onClick={changePopup}>
                    Login here
                  </a>
                </h6>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PatientLoginModal;
