import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../../store/LoaderSlice";
import { membershipService } from "../../../services/membershipService";
import essentialImg from "../../../assets/imges/Maximum Value.png";
import comprehensiveImg from "../../../assets/imges/Transport Services.png";
import premiumImg from "../../../assets/imges/Services Include-.png";
const MembershipStatic = () => {
  const dispatch = useDispatch();
  const [plans, setplans] = useState([]);

  useEffect(() => {
    getPlans();
  }, []);

  const pricingDetails = {
    "Essential Care Plan": {
      single: 59,
      spouse: 30,
      child: 10,
    },
    "Comprehensive Care Plan": {
      single: 79,
      spouse: 50,
      child: 20,
    },
    "Premium Plus Care Plan": {
      single: 199,
      spouse: 90,
      child: 50,
    },
  };

  async function getPlans() {
    dispatch(loader(true));
    const res = await membershipService.getPlans();
    if (res?.success) {
      setplans(res?.membership);
    }
    dispatch(loader(false));
  }

  return (
    <>
      <Row className="g-4 px-4 my-5 ">
        {plans.map((plan, index) => {
          console.log("PLAN", plan.planName);
          const pricing = pricingDetails[plan.planName];
          const imageUrl =
            plan.planName === "Essential Care Plan"
              ? premiumImg
              : plan.planName === "Comprehensive Care Plan"
              ? essentialImg
              : plan.planName === "Premium Plus Care Plan"
              ? comprehensiveImg
              : null;

          return (
            <Col key={plan.planId} md={4}>
              <Card className={`${index === 1 ? "CardCenterCss" : ""} h-100`}>
                <Card.Img
                  variant="top"
                  src={imageUrl}
                  alt={plan.planName}
                  style={{ height: "200px", objectFit: "contain" }}
                />
                <Card.Body>
                  <Card.Title>{plan.planName}</Card.Title>
                  {pricing ? (
                    <>
                      <Card.Text className="my-2">
                        <strong>Price:</strong> ${pricing.single}/month for a
                        single person
                      </Card.Text>
                      <Card.Text>
                        <ul>
                          <li className="my-2">
                            {" "}
                            <i
                              className="fa fa-plus mr-2"
                              aria-hidden="true"
                            ></i>
                            ${pricing.spouse}/month for a spouse
                          </li>
                          <li className="my-2">
                            <i
                              className="fa fa-plus mr-2"
                              aria-hidden="true"
                            ></i>{" "}
                            ${pricing.child}/month per additional child
                          </li>
                        </ul>
                      </Card.Text>
                    </>
                  ) : (
                    <Card.Text>Pricing details unavailable</Card.Text>
                  )}
                </Card.Body>
                <Card.Footer>
                  <a
                    target="_blank"
                    href={
                      plan.planName === "Premium Plus Care Plan"
                        ? "https://urhealthcare.hint.com/signup/premium-plan"
                        : plan.planName === "Comprehensive Care Plan"
                        ? "https://urhealthcare.hint.com/signup/membership"
                        : "https://urhealthcare.hint.com/signup/essential-care-plan"
                    }
                  >
                    <button className="btn btn-primary">Subscribe</button>
                  </a>
                </Card.Footer>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default MembershipStatic;
