import React from "react";
import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {
  formatUSPhoneNumber,
  isValidIndianPhone,
  showNotification,
} from "../../helper/helper";
import { toast } from "react-toastify";
import { patientService } from "../../services/patientServices";

const VerifyPhone = ({
  togglePhoneModal,
  setTogglePhoneModal,
  setToggleOTPModal,
  phone,
  setphone,
  otpregister,
  setotpRegister,
  setshowDocLogin,
}) => {
  const handleClose = () => {
    setTogglePhoneModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidIndianPhone(phone)) {
      const formattedPhone = formatUSPhoneNumber(phone);
      console.log("Formatted Phone = ", formattedPhone);
      const result = await patientService.sendOtpOnPhone(formattedPhone);
      if (result.phone_registered == true) {
        showNotification(
          "error",
          "Please use another number",
          "This number is already associated with another account. Please use another number"
        );
      } else {
        // toast(result.otp);
        setotpRegister(result.otp);
        handleClose();
        setToggleOTPModal(true);
      }
    } else {
      toast("Please enter a valid phone number");
    }
  };

  async function changePopup() {
    handleClose();
    setshowDocLogin(true);
    // dispatch(setdocpopup(true));
  }

  return (
    <Modal
      show={togglePhoneModal}
      onHide={handleClose}
      backdrop="static"
      id="VerifyPhone"
      centered
    >
      <div className="modal-content">
        <div className="modal-body">
          <button
            type="button"
            className="CloseModal"
            onClick={handleClose}
            aria-label="Close"
          >
            &times;
          </button>
          <div className="LoginBox">
            <div className="LoginHead">
              <img src="images/Fav.png" alt="Logo" />
            </div>
            <div className="LoginBody">
              <article>
                <h3>Welcome to Ur Health</h3>
              </article>
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Phone Number"
                    className="form-control"
                    onChange={(e) => setphone(e.target.value)}
                  />
                </Form.Group>
                <div className="ButtonBox mt-2">
                  <Button className="Dark" type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
              <aside>
                <h6>
                  Are you expert care?{" "}
                  <a href="#" onClick={changePopup}>
                    Login here
                  </a>{" "}
                </h6>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyPhone;
