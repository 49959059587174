import axios from "axios";
import { toast } from "react-toastify";
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  // baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30000,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      const doc_token = localStorage.getItem("doctorToken");
      if (doc_token) {
        config.headers.Authorization = `Bearer ${doc_token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for handling response errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      console.error("Response error:", error.response.data);
      console.error("Status code:", error.response.status);

      const status = error.response.status; // Corrected: accessing status from error.response
      if (status === 200) {
        // Handle status 200
      } else if (status === 201) {
        toast("Data Saved");
      } else if (status === 204) {
        toast("No content available");
      } else if (status === 400) {
        console.log("Bad request. Please check your input.");
      } else if (status === 401) {
        toast("Unauthorized. Please login to access this resource.");
      } else if (status === 403) {
        localStorage.clear();
        toast("You have signedin on another device");
        window.location.href = "/";
      } else if (status === 404) {
        toast("Resource not found. Please try again later.");
      } else if (status === 500) {
        console.log("Internal server error. Please try again later.");
      } else {
        toast("Unexpected error occurred.");
      }
    } else if (error.request) {
      console.error("Request error:", error.request);
    } else {
      console.error("Error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
