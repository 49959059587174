import React, { useState } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { doctorService } from "../../services/doctorService";
import {
  getDeviceToken,
  isValidIndianPhone,
  showNotification,
} from "../../helper/helper";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../store/LoaderSlice";
import { setDoctor } from "../../store/DoctorSlice";
import { toast } from "react-toastify";
import Timer from "../timerComponent/Timer";
const DoctorLoginPopup = ({ showDocLogin, setshowDocLogin }) => {
  const dispatch = useDispatch();
  const reduxDoctor = useSelector((state) => state.doctor);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [modal, setmodal] = useState(1);

  //reset password
  const [emailAddress, setEmailAddress] = useState("");

  const [otp, setOtp] = useState(["", "", "", ""]);
  const [correct_otp, setcorrect_otp] = useState("");

  // State for managing the new password and confirmation
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [login_using_otp, setlogin_using_otp] = useState(false);

  const [token_phone_login, settoken_phone_login] = useState("");
  const [doc_phone_login, setdoc_phone_login] = useState({});

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  // Handler for form submission
  const handleSignIn = async (e) => {
    dispatch(loader(true));
    e.preventDefault(); // Prevent default form submission

    // const deviceToken = await getDeviceToken();

    console.log("Sign In with", { email, password });
    // Add your sign-in logic here

    const result = await doctorService.login({
      email,
      password,

      device_type: "website",
    });
    if (result.success) {
      showNotification(
        "success",
        "Login Successful",
        "You have logged in successfuly"
      );
      localStorage.setItem("doctor", JSON.stringify(result.doctor));
      localStorage.setItem("doctorToken", result.token);
      dispatch(setDoctor(result.doctor));
      navigate("/doctor/dashboard");
    } else {
      showNotification(
        "error",
        "Login failed",
        "Please verify email and password"
      );
    }

    dispatch(loader(false));
  };

  // Handler for password visibility toggle
  const togglePasswordVisibility = () => {
    const passwordInput = document.getElementById("password");
    passwordInput.type =
      passwordInput.type === "password" ? "text" : "password";
  };

  const handleClose = () => {
    setlogin_using_otp(false);
    setshowDocLogin(false);
    setOtp(["", "", "", ""]);
    setcorrect_otp("");
    setNewPassword("");
    setConfirmPassword("");
    setConfirmPassword("");
    setPhoneNumber("");
    setNewPassword("");

    setTimeout(() => {
      setmodal(1);
    }, 1000);
  };

  //handler for reset password
  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    if (login_using_otp) {
      sendOtpOnPhone();
    } else {
      console.log("Submit email:", emailAddress);
      const res = await doctorService.sendOtp({ email: emailAddress });
      if (res.success) {
        setmodal(3);
        setcorrect_otp(res.otp);
      } else {
        toast("please verify email");
      }
    }
  };

  // Handle change for individual OTP input
  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  // Handle form submission
  const handleOtp = (e) => {
    e.preventDefault(); // Prevent default form submission
    console.log("Submitted OTP:", otp.join(""));

    if (otp.join("") == correct_otp) {
      // toast("OTP Correct");
      setmodal(4);
    } else {
      toast("Incorrect OTP");
    }
    // Add logic here to verify OTP
  };

  async function handleOtpLogin(e) {
    e.preventDefault(); // Prevent default form submission
    console.log("Submitted OTP:", otp.join(""));

    if (otp.join("") == correct_otp) {
      showNotification(
        "success",
        "Login Successful",
        "You have successfuly logged in."
      );
      navigate("/doctor/home");
      localStorage.setItem("doctor", JSON.stringify(doc_phone_login));
      localStorage.setItem("doctorToken", token_phone_login);
      dispatch(setDoctor(doc_phone_login));
    } else {
      toast("Incorrect OTP");
    }
  }

  // Handle input focus and auto-focus next input
  const handleFocus = (e, index) => {
    if (e.target.value.length === 1 && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      const res = await doctorService.updatePass({
        email: emailAddress,
        newpassword: newPassword,
        confirmpassword: confirmPassword,
      });

      if (res.success) {
        showNotification(
          "success",
          "Password reset",
          "Your password was reset successfuly"
        );

        setmodal(1);
      } else {
        showNotification(
          "error",
          "Something went wrong",
          "Your password reset failed"
        );
      }
    } else {
      toast("Passwords do not match");
      // Add logic to handle mismatched passwords
    }
  };

  const sendOtpOnPhone = async () => {
    console.log("Phone number submitted:", phoneNumber);
    if (isValidIndianPhone(phoneNumber)) {
      const res = await doctorService.sendOtpOnPhone({ phone: phoneNumber });
      if (res.success) {
        setmodal(3);
        setcorrect_otp(res.otp);
      } else {
        toast("please verify phone number");
      }
    } else {
      toast("phone number is not valid");
    }
  };

  async function loginUsingPhone() {
    console.log("Phone number submitted:", phoneNumber);
    if (isValidIndianPhone(phoneNumber)) {
      const res = await doctorService.loginusingphone({
        phone: phoneNumber,
        device_type: "website",
      });
      if (res.success) {
        setmodal(6);
        setcorrect_otp(res.otp);
        settoken_phone_login(res.token);
        setdoc_phone_login(res.doc);
      } else {
        toast("please verify phone number");
      }
    } else {
      toast("phone number is not valid");
    }
  }

  return (
    <Modal show={showDocLogin} backdrop="static" onHide={handleClose}>
      <Modal.Body>
        {modal == 1 ? (
          <>
            <div className="modal-body">
              <button
                type="button"
                className="CloseModal"
                onClick={handleClose}
              >
                ×
              </button>
              <div className="LoginBox">
                <div className="LoginHead">
                  <img src="/images/Fav.png" alt="Logo" />
                </div>
                <div className="LoginBody">
                  <article>
                    <h3>Login to Your Account</h3>
                    <p>
                      We’ll use your email to find your account if you have one
                    </p>
                  </article>
                  <form onSubmit={handleSignIn}>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="Email Address"
                        required
                      />
                      <label>Email Address</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Email Password"
                        required
                      />
                      <label>Email Password</label>
                      <span className="Icon" onClick={togglePasswordVisibility}>
                        <img src="/images/Eye.png" alt="Toggle visibility" />
                      </span>
                    </div>
                    <div className="form-group">
                      <h5>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setmodal(2);
                            // Add logic to open the reset password modal
                          }}
                        >
                          Reset Password?
                        </a>
                      </h5>
                    </div>
                    <div className="ButtonBox">
                      <button type="submit" className="Dark" id="Login">
                        Sign In
                      </button>
                      <button
                        type="button"
                        className="Light"
                        onClick={() => {
                          setmodal(5);
                          setlogin_using_otp(true);
                        }}
                      >
                        Login Using OTP
                      </button>
                    </div>
                  </form>
                  {/* 
          <aside>
            <h6>
              Are you seeking care? <a href="">Login here</a>
            </h6>
          </aside> 
          */}
                </div>
              </div>
            </div>
          </>
        ) : null}

        {modal == 2 ? (
          <div className="LoginBox">
            <button type="button" className="CloseModal" onClick={handleClose}>
              ×
            </button>
            <div className="LoginHead">
              <img src="/images/Fav.png" alt="Logo" />
            </div>
            <div className="LoginBody">
              <button
                type="button"
                className="CloseModal"
                onClick={handleClose}
              >
                ×
              </button>
              <article>
                <h3>Reset Password</h3>
                <p>
                  We’ll send an OTP to your email address to verify your account
                </p>
              </article>
              <form onSubmit={handleResetPasswordSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)} // Inline event handler
                    placeholder="Email Address"
                    required
                  />
                  <label>Email Address</label>
                </div>
                <div className="ButtonBox">
                  <button
                    type="submit"
                    className="Dark"
                    // Inline event handler is not needed here since it's handled by the form's onSubmit
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : null}

        {modal == 3 ? (
          <>
            <div>
              <div className="LoginBox">
                <button
                  type="button"
                  className="CloseModal"
                  onClick={handleClose}
                >
                  ×
                </button>
                <div className="LoginHead">
                  <img src="/images/Fav.png" alt="Logo" />
                </div>
                <div className="LoginBody">
                  <article>
                    <h3>OTP Code Verification</h3>
                    {login_using_otp ? (
                      <p>
                        Code has been sent to ********{phoneNumber.substring(8)}
                      </p>
                    ) : (
                      <p>Code has been sent to ******@gmail.com</p>
                    )}
                  </article>
                  <form onSubmit={handleOtp}>
                    <div className="form-group">
                      <div className="OTPBox">
                        {[0, 1, 2, 3].map((index) => (
                          <input
                            key={index}
                            id={`otp-${index}`}
                            type="text"
                            className="form-control"
                            maxlength="1"
                            value={otp[index]}
                            onChange={(e) =>
                              handleOtpChange(index, e.target.value)
                            }
                            onFocus={(e) => e.target.select()} // Select input text on focus
                            onKeyUp={(e) => handleFocus(e, index)}
                            placeholder=""
                          />
                        ))}
                      </div>
                    </div>
                    <div className="ButtonBox">
                      <button
                        type="submit"
                        className="Gray"
                        // Inline event handler is not needed here since it's handled by the form's onSubmit
                      >
                        Verify
                      </button>
                    </div>
                  </form>
                  <aside>
                    {/* <h6>
                      Resend Code In <strong>55</strong>s
                    </h6> */}
                    <Timer />
                  </aside>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {modal == 4 ? (
          <>
            <div>
              <div className="LoginBox">
                <button
                  type="button"
                  className="CloseModal"
                  onClick={handleClose}
                >
                  ×
                </button>
                <div className="LoginHead">
                  <img src="/images/Fav.png" alt="Logo" />
                </div>
                <div className="LoginBody">
                  <article>
                    <h3>Reset Password</h3>
                    {/* <p>We’ll send an OTP to your email address to verify your account</p> */}
                  </article>
                  <form onSubmit={handlePasswordUpdate}>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)} // Inline event handler for password input
                        placeholder="Change Password"
                        required
                        pattern="^(?=.*[A-Z])(?=.*[@$!%*?&])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$"
                        title="Password must be at least 6 characters long, include at least one special character, one uppercase letter and a digit."
                      />
                      <label>Change Password</label>
                      <span className="Icon">
                        <img src="images/Eye.png" alt="Toggle visibility" />
                      </span>
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)} // Inline event handler for confirm password input
                        placeholder="Confirm Password"
                        required
                        pattern="^(?=.*[A-Z])(?=.*[@$!%*?&])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$"
                        title="Password must be at least 6 characters long, include at least one special character, one uppercase letter and a digit."
                      />
                      <label>Confirm Password</label>
                      <span className="Icon">
                        <img src="images/Eye.png" alt="Toggle visibility" />
                      </span>
                    </div>
                    <div className="ButtonBox">
                      <button
                        type="submit"
                        className="Dark"
                        // Inline event handler is not needed here since it's handled by the form's onSubmit
                      >
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {modal == 5 ? (
          <>
            <div className="modal-body">
              <button
                type="button"
                className="CloseModal"
                onClick={handleClose}
              >
                ×
              </button>
              <div className="LoginBox">
                <div className="LoginHead">
                  <img src="/images/Fav.png" alt="Logo" />
                </div>
                <div className="LoginBody">
                  <article>
                    <h3>Welcome to Ur Health </h3>
                    <p>
                      We’ll use your mobile number to find your account if you
                      have one
                    </p>
                  </article>
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={phoneNumber}
                        onChange={(event) => {
                          setPhoneNumber(event.target.value);
                        }}
                        required
                      />
                      <label htmlFor="phoneNumber">Phone Number</label>
                    </div>
                    <div className="ButtonBox">
                      <button
                        type="button"
                        className="Dark"
                        onClick={loginUsingPhone}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        className="Light"
                        onClick={() => {
                          setmodal(6);
                          setlogin_using_otp(false);
                        }}
                      >
                        Login Using Password
                      </button>
                    </div>
                  </form>
                  <aside>
                    <h6>
                      Are you seeking care?{" "}
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          setmodal(1);
                          setlogin_using_otp(false);
                        }}
                      >
                        Login here
                      </a>
                    </h6>
                  </aside>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {modal == 6 ? (
          <>
            <div>
              <div className="LoginBox">
                <button
                  type="button"
                  className="CloseModal"
                  onClick={handleClose}
                >
                  ×
                </button>
                <div className="LoginHead">
                  <img src="/images/Fav.png" alt="Logo" />
                </div>
                <div className="LoginBody">
                  <article>
                    <h3>OTP Code Verification </h3>
                    {login_using_otp ? (
                      <p>
                        Code has been sent to ********{phoneNumber.substring(8)}
                      </p>
                    ) : (
                      <p>Code has been sent to ******@gmail.com</p>
                    )}
                  </article>
                  <form onSubmit={handleOtpLogin}>
                    <div className="form-group">
                      <div className="OTPBox">
                        {[0, 1, 2, 3].map((index) => (
                          <input
                            key={index}
                            id={`otp-${index}`}
                            type="text"
                            className="form-control"
                            maxlength="1"
                            value={otp[index]}
                            onChange={(e) =>
                              handleOtpChange(index, e.target.value)
                            }
                            onFocus={(e) => e.target.select()} // Select input text on focus
                            onKeyUp={(e) => handleFocus(e, index)}
                            placeholder=""
                          />
                        ))}
                      </div>
                    </div>
                    <div className="ButtonBox">
                      <button
                        type="submit"
                        className="Gray"
                        // Inline event handler is not needed here since it's handled by the form's onSubmit
                      >
                        Verify
                      </button>
                    </div>
                  </form>
                  <aside>
                    {/* <h6>
                      Resend Code In <strong>55</strong>s
                    </h6> */}
                    <Timer />
                  </aside>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default DoctorLoginPopup;
