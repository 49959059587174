import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import DatePicker from "react-horizontal-datepicker";
import moment from "moment";
import { appointmentServices } from "../../services/appointmentServices";
import { patientService } from "../../services/patientServices";
import { membershipService } from "../../services/membershipService";
import { calculateLastDate } from "../../helper/helper";
import {
  addFeeAndTax,
  isCurrentDateEqualToStoredDate,
} from "../../helper/helper";
import { IoMdArrowBack } from "react-icons/io";
import {
  calculateAge,
  generateTransactionId,
  isStoredDateAfterCurrentDate,
  showNotification,
} from "../../helper/helper";
import { setBookingPopup } from "../../store/BookingPopUpSlice";
import { setLogin } from "../../store/LoginPopupSlice";
import { setappointmentTrack } from "../../store/AppointmentOngoing";
import { setBookingType } from "../../store/BookingTypeTrackSlice";
import { useSelector, useDispatch } from "react-redux";
import { loader } from "../../store/LoaderSlice";
import PayUsingCash from "../innerPopups/VirtualPrimary/PayUsingCash";
import PayUsingINsuranceVirtualPrimary from "../innerPopups/VirtualPrimary/PayUsingInsuranceVirtualPrimary";
import female from "../../assets/imges/Avatar.png";
import male from "../../assets/imges/Avatar-1.png";
import MembershipComponent from "../MembershipComponent/MembershipComponent";
const VirtualPrimary = ({ virtualPrimary, setvirtualPrimary }) => {
  const reduxUser = useSelector((state) => state.user);
  const [user, setuser] = useState({});
  const [reasonTovisit, setreasonTovisit] = useState("");
  const [bookingTabs, setbookingTabs] = useState(0);
  const [selectedDate, setselectedDate] = useState("");
  const [doctors, setdoctors] = useState([]);
  const [family, setfamily] = useState([]);
  const [filteredFamily, setfilteredFamily] = useState([]);
  const [selectedSlot, setselectedSlot] = useState("");
  const [selectedDoc, setselectedDoc] = useState("");
  const [membership, setmembership] = useState([]);
  const [purchasedPlanId, setpurchasedPlanId] = useState("");
  const [additionalFee, setadditionalFee] = useState({});
  const [selectedSlotStartTime, setselectedSlotStartTime] = useState("");
  const [selectedSlotEndTime, setselectedSlotEndTime] = useState("");
  const [appointmentFor, setappointmentFor] = useState("");
  const [trackSlotSelect, settrackSlotSelect] = useState(false);
  const [filteredDoc, setfilteredDoc] = useState([]);
  const [selectedMembership, setselectedMembership] = useState(0);
  const [planData, setplanData] = useState({});
  const dispatch = useDispatch();

  //
  const currentDate = moment().format("YYYY-MM-DD HH:mm A ");
  const currentDayOfWeek = moment().format("dddd").toLowerCase();
  const [familyData, setFamilyData] = useState({
    relation: "",
    firstname: "",
    lastname: "",
    gender: "",
    dob: "",
    patient: "",
  });

  //states for popups
  const [toggleCashPopup, settoggleCashPopup] = useState(false);
  const [toggleInsurancePopup, settoggleInsurancePopup] = useState(false);
  const today = moment().format("YYYY-MM-DD");

  const [tabPage, setTabPage] = useState([]);

  //...................

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("redux==>", reduxUser);
    setuser(reduxUser);
    getTaxes();
    getMembership();
    // getDocs();
  }, []);
  useEffect(() => {
    setFamilyData({ ...familyData, patient: reduxUser?._id });
  }, [reduxUser]);

  useEffect(() => {
    console.log("selected Date", selectedDate);
    if (selectedDate != "") {
      getDocs();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (trackSlotSelect == true) {
      getFam(reduxUser);
    }
  }, [trackSlotSelect]);

  useEffect(() => {
    if (bookingTabs == 2) {
      getFam();
    }
    if (bookingTabs == 3) {
      getTaxes();
    }

    setTabPage((prev) => [...prev, bookingTabs]);
  }, [bookingTabs]);

  async function getDocs() {
    dispatch(loader(true));
    const res = await appointmentServices.getDocWithSlotsVirtualPrimary(
      moment(selectedDate).format("dddd"),
      selectedDate
    );
    setdoctors(res);
    dispatch(loader(false));
  }

  function handleClose() {
    setbookingTabs(0);
    setvirtualPrimary(false);
    setreasonTovisit("");
    setselectedSlot("");

    //
    // setselectedDate("");
    // setdoctors([]);
    // setfamily([]);
    // setfilteredFamily([]);
    // setselectedDoc("");
    // setmembership([]);
    // setpurchasedPlanId("");
    // setadditionalFee({});
    // setselectedSlotStartTime("");
    // setselectedSlotEndTime("");
    // setappointmentFor("");
    // settrackSlotSelect(false);
    // setfilteredDoc([]);
    // setselectedMembership(0);
    // setplanData({});
  }

  const selectedDay = (val) => {
    // console.log(val);

    setselectedDate(moment(val).format("YYYY/MM/DD"));
  };
  async function filterDoc(id) {
    console.log("family id", id);
    const filteredDoc = doctors.filter((item, index) => item?._id == id);
    if (filteredDoc.length) {
      setfilteredDoc(filteredDoc);
    }
    console.log("filteredDoc", filteredDoc);
  }

  function isTimeAfterCurrentTime(inputTime) {
    // Parse the input time using Moment.js
    const inputMoment = moment(inputTime, "hh:mm A");

    // Get the current time
    const currentTime = moment();

    // Compare input time with current time and return result
    return inputMoment.isAfter(currentTime);
  }

  async function filterFamily(id, type) {
    if (type == "patient") {
      setappointmentFor("patient");
      setbookingTabs(4);
    } else {
      setappointmentFor("family");

      console.log("family id", id);
      const filteredFam = family.filter((item, index) => item?._id == id);
      if (filteredFam.length) {
        setfilteredFamily(filteredFam);
        setbookingTabs(4);
      }
      console.log("filteredFam", filteredFam);
    }
  }

  const familyDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFamilyData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSaveDetails = async () => {
    console.log("Family Data:", familyData);

    const res = await patientService.addFamilyMem(familyData);
    if (res) {
      getFam(reduxUser);
      showNotification(
        "success",
        "Family Member Added",
        "Family member was added successfuly."
      );
      setbookingTabs(2);
    } else {
      showNotification("error", "Not Saved", "Something went wrong.");
    }
    setFamilyData({
      relation: "",
      firstname: "",
      lastname: "",
      gender: "",
      dob: "",
    });
  };

  async function getFam(data) {
    dispatch(loader(true));
    const res = await patientService.getFamily(reduxUser._id);
    if (res?.family.length) {
      console.log("res.family", res.family);
      setfamily(res.family);
    }
    dispatch(loader(false));
  }

  async function getMembership() {
    const res = await membershipService.getMemberships();
    if (res.success) {
      setmembership(res.membership);
    }
  }

  async function getTaxes() {
    const res = await patientService.getTaxes();
    setadditionalFee(res.data[0]);
  }

  // async function checkMembershipStatus() {
  //   dispatch(loader(true));
  //   const res = await membershipService.checkMembership(user._id);
  //   if (
  //     res.plans.is_plan_onhold_due_to_payment == false &&
  //     res.plans.is_plan_over == false
  //   ) {
  //     console.log("CHECK MEMBERSHP CONDITION RAN", res);
  //     dispatch(loader(false));
  //     setpurchasedPlanId(res.plans._id);
  //     return true;
  //   } else {
  //     console.log("CHECK MEMBERSHP CONDITION FAILED", res);
  //     dispatch(loader(false));
  //     return false;
  //   }
  // }

  //old
  // async function checkMembershipStatus() {
  //   dispatch(loader(true));
  //   const res = await membershipService.checkMembership(reduxUser?._id);
  //   if (res?.plans?.length) {
  //     if (
  //       res?.plans[0]?.is_plan_onhold_due_to_payment == false &&
  //       res?.plans[0]?.is_plan_over == false
  //     ) {
  //       console.log("CHECK MEMBERSHP CONDITION RAN", res);
  //       dispatch(loader(false));
  //       setpurchasedPlanId(res.plans[0]?._id);
  //       return true;
  //     } else {
  //       console.log("CHECK MEMBERSHP CONDITION FAILED", res);
  //       dispatch(loader(false));
  //       return false;
  //     }
  //   } else {
  //     dispatch(loader(false));
  //     showNotification(
  //       "error",
  //       "No plans purchased",
  //       "You have not purchased any plans yet."
  //     );
  //     setbookingTabs(6);
  //   }
  // }

  //new
  async function checkMembershipStatus() {
    dispatch(loader(true));
    const res = await membershipService.checkMembership(reduxUser?._id);
    if (res?.plans?.length) {
      if (
        res?.plans[0]?.is_plan_over == false &&
        res?.plans[0]?.is_plan_cancelled == false
      ) {
        console.log("CHECK MEMBERSHP CONDITION RAN", res);
        dispatch(loader(false));
        setpurchasedPlanId(res.plans[0]?._id);
        return true;
      } else {
        console.log("CHECK MEMBERSHP CONDITION FAILED", res);
        dispatch(loader(false));
        return false;
      }
    } else {
      dispatch(loader(false));
      showNotification(
        "error",
        "No plans purchased",
        "You have not purchased any plans yet."
      );
      setbookingTabs(6);
    }
  }

  async function handleAppointmentBooking(type, insuranceId) {
    if (type == "membership") {
      const booking = {
        doctor_id: selectedDoc,
        patient_id: reduxUser?._id,

        booking_date: selectedDate,
        slot_id: selectedSlot,
        appointment_type: "virtual",
        care_type: "primary",
        reason_to_visit: reasonTovisit,
        is_cancelled: false,
        is_appointment_for_family: appointmentFor == "patient" ? false : true,
        booked_by_insurance_or_membership: "cash",

        payment_amount: addFeeAndTax(
          additionalFee.gst,
          additionalFee.platform_charge,
          additionalFee.service_charge,
          additionalFee.others,
          filteredDoc[0].doctor_fees
        ),
      };

      if (appointmentFor == "family") {
        booking.family_member_id = filteredFamily[0]?._id;
      }

      dispatch(loader(true));

      const res = await appointmentServices.bookappointment(booking);
      if (res?.success) {
        handleClose();

        settoggleCashPopup(false);
        showNotification(
          "success",
          "Appointment created",
          "Your appointment was successfuly booked"
        );
      } else {
        handleClose();
        showNotification("error", "appointment not created", res.msg);
      }

      dispatch(loader(false));
    }

    // if (type == "membership") {
    //   const booking = {
    //     doctor_id: selectedDoc,
    //     patient_id: reduxUser?._id,
    //     purchased_plan_id: purchasedPlanId,
    //     booking_date: selectedDate,
    //     slot_id: selectedSlot,
    //     appointment_type: "virtual",
    //     care_type: "primary",
    //     reason_to_visit: reasonTovisit,
    //     is_cancelled: false,
    //     is_appointment_for_family: appointmentFor == "patient" ? false : true,
    //     booked_by_insurance_or_membership: "membership",
    //     transaction_id: generateTransactionId(),
    //     payment_amount: addFeeAndTax(
    //       additionalFee.gst,
    //       additionalFee.platform_charge,
    //       additionalFee.service_charge,
    //       additionalFee.others,
    //       filteredDoc[0].doctor_fees
    //     ),
    //   };

    //   if (appointmentFor == "family") {
    //     booking.family_member_id = filteredFamily[0]?._id;
    //   }

    //   dispatch(loader(true));

    //   const res = await appointmentServices.bookappointment(booking);
    //   if (res?.success) {
    //     handleClose();
    //     showNotification(
    //       "success",
    //       "Appointment created",
    //       "Your appointment was successfuly booked"
    //     );
    //   } else {
    //     handleClose();
    //     showNotification("error", "appointment not created", res.msg);
    //   }

    //   dispatch(loader(false));
    // }
    else if (type == "cash") {
      const booking = {
        doctor_id: selectedDoc,
        patient_id: reduxUser?._id,

        booking_date: selectedDate,
        slot_id: selectedSlot,
        appointment_type: "virtual",
        care_type: "primary",
        reason_to_visit: reasonTovisit,
        is_cancelled: false,
        is_appointment_for_family: appointmentFor == "patient" ? false : true,
        booked_by_insurance_or_membership: "cash",

        payment_amount: addFeeAndTax(
          additionalFee.gst,
          additionalFee.platform_charge,
          additionalFee.service_charge,
          additionalFee.others,
          filteredDoc[0].doctor_fees
        ),
      };

      if (appointmentFor == "family") {
        booking.family_member_id = filteredFamily[0]?._id;
      }

      dispatch(loader(true));

      const res = await appointmentServices.bookappointment(booking);
      if (res?.success) {
        handleClose();

        settoggleCashPopup(false);
        showNotification(
          "success",
          "Appointment created",
          "Your appointment was successfuly booked"
        );
      } else {
        handleClose();
        showNotification("error", "appointment not created", res.msg);
      }

      dispatch(loader(false));
    } else if (type == "insurance") {
      const booking = {
        doctor_id: selectedDoc,
        patient_id: reduxUser?._id,
        purchased_plan_id: purchasedPlanId,
        booking_date: selectedDate,
        slot_id: selectedSlot,
        appointment_type: "virtual",
        care_type: "primary",
        reason_to_visit: reasonTovisit,
        is_cancelled: false,
        is_appointment_for_family: appointmentFor == "patient" ? false : true,
        booked_by_insurance_or_membership: "insurance",
        insurance_id: insuranceId,
        payment_amount: addFeeAndTax(
          additionalFee.gst,
          additionalFee.platform_charge,
          additionalFee.service_charge,
          additionalFee.others,
          filteredDoc[0].doctor_fees
        ),
      };

      if (appointmentFor == "family") {
        booking.family_member_id = filteredFamily[0]?._id;
      }

      dispatch(loader(true));

      const res = await appointmentServices.bookappointment(booking);
      if (res?.success) {
        handleClose();
        showNotification(
          "success",
          "Appointment created",
          "Your appointment was successfuly booked"
        );
      } else {
        handleClose();
        showNotification("error", "appointment not created", res.msg);
      }

      dispatch(loader(false));
    }
  }

  function storePlanData(
    id,
    first_payment,
    relationOfPerson,
    membership_discount_cost,
    membership_name,
    membership_description,
    membership_duration,
    membership_cost
  ) {
    const length = 8;
    const transaction_id = generateTransactionId(length, "number");
    const currentDate = moment().format("YYYY/MM/DD");

    setplanData({
      membership_id: id,
      patient_id: reduxUser?._id,
      transaction_id: transaction_id,
      plan_start: currentDate,
      first_payment: first_payment,
      relation_of_members_included_in_the_plan: relationOfPerson,
      membership_discount_cost: membership_discount_cost,
      membership_name: membership_name,
      membership_description: membership_description,
      membership_duration: membership_duration,
      membership_cost: membership_cost,
    });
  }

  async function PurchasePlan() {
    dispatch(loader(true));

    const res = await membershipService.purchasePlan(planData);
    if (res) {
      showNotification(
        "success",
        "Payment Successful",
        "Your plan purchase was successful"
      );

      checkMembershipStatus();

      setbookingTabs(7);
    } else {
      showNotification(
        "error",
        "Payment Failed",
        "Your plan purchase was not successful"
      );
    }

    dispatch(loader(false));
  }

  return (
    <>
      <PayUsingCash
        toggleCashPopup={toggleCashPopup}
        settoggleCashPopup={settoggleCashPopup}
        selectedDate={selectedDate}
        selectedSlotStartTime={selectedSlotStartTime}
        selectedDoc={selectedDoc}
        additionalFee={additionalFee}
        filteredDoc={filteredDoc}
        handleAppointmentBooking={handleAppointmentBooking}
      />

      <PayUsingINsuranceVirtualPrimary
        toggleInsurancePopup={toggleInsurancePopup}
        settoggleInsurancePopup={settoggleInsurancePopup}
        selectedDate={selectedDate}
        selectedSlotStartTime={selectedSlotStartTime}
        selectedDoc={selectedDoc}
        additionalFee={additionalFee}
        filteredDoc={filteredDoc}
        handleAppointmentBooking={handleAppointmentBooking}
      />
      <Modal backdrop="static" show={virtualPrimary} onHide={handleClose}>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            {bookingTabs == 0 ? null : (
              <span
                onClick={() => {
                  setTabPage((prevTabPage) => {
                    if (prevTabPage.length > 1) {
                      // Clone the array to avoid mutation
                      const updatedTabs = [...prevTabPage];
                      const lastTab = updatedTabs[updatedTabs.length - 2]; // Get second-to-last tab

                      // Update bookingTabs with the previous tab
                      setbookingTabs(lastTab);

                      // Remove the last tab and update tabPage
                      return updatedTabs.slice(0, -1);
                    }
                    return prevTabPage;
                  });
                }}
              >
                <IoMdArrowBack />
              </span>
            )}
            <span onClick={handleClose}>
              <button type="button" className="CloseModal">
                ×
              </button>
            </span>
          </div>
          {bookingTabs == 0 ? (
            <>
              <div className="BookingModalArea">
                <h3 className="Title">
                  Explain what is reason <br />
                  for visit.
                </h3>
                <Form className="CommonFormModal">
                  <Form.Group controlId="formTextarea">
                    <Form.Control
                      as="textarea"
                      rows={8}
                      value={reasonTovisit}
                      onChange={(e) => setreasonTovisit(e.target.value)}
                      placeholder="Write your concern here"
                    />
                  </Form.Group>
                  <div className="ButtonBox ButtonBoxNew mt-3">
                    <Button
                      className="dark FolderPermissionId"
                      onClick={() => {
                        if (reasonTovisit.length < 10) {
                          toast("Please describe in at least 10 words");
                        } else {
                          setbookingTabs(1);
                        }
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </Form>
              </div>
            </>
          ) : null}

          {bookingTabs == 1 ? (
            <>
              <>
                <div className="ModalTopArea">
                  <div className="BookingModalArea">
                    <h3 className="Title">Schedule Virtual Call</h3>
                    {/* <div className="CommonFormModal">
                      <div className="row">
                        <div className="col-lg-7">
                          <div className="form-group">
                            <input type="date" className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div className="form-group">
                            <select className="form-control">
                              <option>Select</option>
                              <option>California</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {/* <img src="images/Date.png" /> */}
                    <div className="mx-5 py-2 mb-2">
                      <DatePicker getSelectedDay={selectedDay} />
                    </div>

                    {doctors?.length ? (
                      doctors?.map((doc, index) => (
                        <div key={index} className="SelectTimeBox">
                          <aside>
                            <span className="Profile">
                              <img
                                className="image-avatar"
                                src={
                                  doc?.profile_pic
                                    ? doc?.profile_pic
                                    : doc?.gender?.toLowerCase() === "male"
                                    ? male
                                    : female
                                }
                              />
                            </span>
                            <h3 className="text-capitalize">
                              {doc.firstname} {doc.lastname}
                            </h3>
                            <ul>
                              <li>
                                <span>
                                  <img src="images/virtual-icon.png" />
                                </span>
                                Virtual
                              </li>
                              {/* <li>
                                <span>
                                  <img src="images/location-icon.png" />
                                </span>
                                7000 mi away
                              </li> */}
                            </ul>
                          </aside>
                          <p>
                            <span>
                              <img src="images/time-icon.png" />
                            </span>
                            <span className="text-capitalize">
                              {currentDayOfWeek}
                            </span>{" "}
                            , {currentDate}
                          </p>
                          <div className="SelectTimeBody">
                            <h4>Select Time</h4>

                            <ul>
                              {doc.slots.map((item, index) => (
                                <li
                                  key={index}
                                  className={`${
                                    item.is_slot_booked == true
                                      ? "AlreadyBooked"
                                      : isTimeAfterCurrentTime(
                                          item.start_time
                                        ) ||
                                        isStoredDateAfterCurrentDate(
                                          selectedDate
                                        )
                                      ? "Available"
                                      : "NotAvailable"
                                  } `}
                                  onClick={() => {
                                    filterDoc(doc?._id);
                                    if (item.is_slot_booked == true) {
                                      toast("this slot is already booked.");
                                    } else if (
                                      !isTimeAfterCurrentTime(
                                        item.start_time
                                      ) &&
                                      isCurrentDateEqualToStoredDate(
                                        selectedDate
                                      )
                                    ) {
                                      toast("slot is not available");
                                    } else {
                                      if (
                                        trackSlotSelect &&
                                        selectedSlot == item?._id
                                      ) {
                                        setselectedDoc("");
                                        setselectedSlot("");
                                        settrackSlotSelect(false);
                                      } else {
                                        setselectedSlotStartTime(
                                          item?.start_time
                                        );
                                        setselectedSlotEndTime(item.end_time);
                                        setselectedDoc(doc?._id);
                                        setselectedSlot(item?._id);
                                        settrackSlotSelect(true);
                                        console.log(
                                          "start time",
                                          selectedSlotStartTime
                                        );
                                      }
                                    }
                                  }}
                                >
                                  <aside
                                    className={`${
                                      selectedDoc == doc?._id &&
                                      selectedSlot == item?._id
                                        ? "selected"
                                        : ""
                                    }`}
                                  >
                                    <span>{item.start_time}</span>
                                  </aside>
                                </li>
                              ))}
                              {/* <li className="AlreadyBooked">
                            <input type="radio" name="selectTime" />
                            <aside>
                              <span>06:00 am</span>
                            </aside>
                          </li>
                          <li className="AlreadyBooked">
                            <input type="radio" name="selectTime" />
                            <aside>
                              <span>06:00 am</span>
                            </aside>
                          </li>
                          <li className="NotAvailable">
                            <input type="radio" name="selectTime" />
                            <aside>
                              <span>06:00 am</span>
                            </aside>
                          </li>
                          <li className="NotAvailable">
                            <input type="radio" name="selectTime" />
                            <aside>
                              <span>06:00 am</span>
                            </aside>
                          </li>
                          <li className="NotAvailable">
                            <input type="radio" name="selectTime" />
                            <aside>
                              <span>06:00 am</span>
                            </aside>
                          </li>
                          <li className="Available">
                            <input type="radio" name="selectTime" />
                            <aside>
                              <span>06:00 am</span>
                            </aside>
                          </li> */}
                            </ul>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-center my-5 ">
                        No doctors availabe today.
                      </div>
                    )}

                    <div className="ButtonBox ButtonBoxNew">
                      <button
                        className="Dark"
                        onClick={() => {
                          if (selectedDoc && selectedSlot) {
                            if (reduxUser) {
                              setbookingTabs(2);
                            } else {
                              console.log("Running else block");
                              dispatch(setappointmentTrack(true));
                              // dispatch(setBookingPopup(false));
                              dispatch(setLogin(true));
                            }
                          } else {
                            toast("please select a slot to continue");
                          }
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </>
          ) : null}

          {bookingTabs == 2 ? (
            <>
              <div className="modal-body">
                <div className="ModalTopArea">
                  <div className="BookingModalArea text-capitalize">
                    <h3 className="Title">
                      Who is the <br />
                      appointment for?
                    </h3>
                    <div className="AppointmentPersonBox">
                      <aside
                        onClick={() => filterFamily(reduxUser?._id, "patient")}
                      >
                        <span className="Profile">
                          <img
                            className="image-avatar"
                            src={
                              reduxUser?.profile_pic
                                ? reduxUser?.profile_pic
                                : reduxUser?.gender?.toLowerCase() === "male"
                                ? male
                                : female
                            }
                          />
                        </span>
                        <h3 className="text-capitalize">
                          {reduxUser?.firstname}
                        </h3>
                        <ul>
                          <li>{calculateAge(reduxUser?.dob)}</li>
                          <li>{reduxUser?.gender}</li>
                        </ul>
                        <span className="RightArrow">
                          <i
                            className="fa fa-chevron-right"
                            aria-hidden="true"
                          />
                        </span>
                      </aside>
                      {family.length
                        ? family.map((item, index) => (
                            <aside
                              key={index}
                              onClick={() => filterFamily(item?._id, "family")}
                            >
                              <span className="Profile">
                                <img
                                  className="image-avatar"
                                  src={
                                    item?.profile_pic
                                      ? item?.profile_pic
                                      : item?.gender?.toLowerCase() === "male"
                                      ? male
                                      : female
                                  }
                                />
                              </span>
                              <h3>{item?.firstname}</h3>
                              <ul>
                                <li>{calculateAge(item?.dob)}</li>
                                <li>{item?.gender}</li>
                              </ul>
                              <span className="RightArrow">
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                />
                              </span>
                            </aside>
                          ))
                        : null}
                    </div>
                    <div className="text-center">
                      <a
                        href="javascript:void(0);"
                        className="AddPatientBtn"
                        onClick={() => setbookingTabs(3)}
                      >
                        <i className="fa fa-plus" aria-hidden="true" />
                        Add Patient
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {bookingTabs == 3 ? (
            <>
              <div className="modal-body">
                <div className="ModalTopArea">
                  <div className="BookingModalArea">
                    <h3 className="Title">Add Patient Info</h3>
                  </div>
                  <form>
                    <div className="LoginBox">
                      <div className="LoginBody">
                        <div className="form-group">
                          {/* <label>Select Relationship</label> */}
                          <select
                            className="form-control"
                            name="relation"
                            value={familyData.relation}
                            onChange={familyDataChange}
                          >
                            <option value="">Select Relationship</option>
                            <option value="Brother">Brother</option>
                            <option value="Sister">Sister</option>
                            <option value="Son">Son</option>
                            <option value="Daughter">Daughter</option>
                            <option value="Husband">Husband</option>
                            <option value="Wife">Wife</option>
                            <option value="Father">Father</option>
                            <option value="Mother">Mother</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="firstname"
                            value={familyData.firstname}
                            onChange={familyDataChange}
                          />
                          <label>First Name</label>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="lastname"
                            value={familyData.lastname}
                            onChange={familyDataChange}
                          />
                          <label>Last Name</label>
                        </div>
                        <div className="SelectGender">
                          <div className="form-group">
                            <label>Select Gender</label>
                            <ul>
                              <li>
                                <input
                                  type="radio"
                                  name="gender"
                                  value="male"
                                  checked={familyData.gender === "male"}
                                  onChange={familyDataChange}
                                />
                                <aside>
                                  <span>Male</span>
                                </aside>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  name="gender"
                                  value="female"
                                  checked={familyData.gender === "female"}
                                  onChange={familyDataChange}
                                />
                                <aside>
                                  <span>Female</span>
                                </aside>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="SelectGender">
                          <div className="form-group">
                            <label>Date of Birth</label>
                            <input
                              type="date"
                              className="form-control"
                              name="dob"
                              value={familyData.dob}
                              onChange={familyDataChange}
                              max={today}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="ButtonBox ButtonBoxNew">
                        <button
                          type="button"
                          className="Dark FolderPermissionId"
                          onClick={handleSaveDetails}
                        >
                          Save Details
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          ) : null}
          {bookingTabs == 4 ? (
            <>
              <div className="modal-body">
                <div className="ModalTopArea">
                  <div className="BookingModalArea">
                    <h3 className="Title">Confirm Appointment</h3>
                    <h3 className="BoxHeading">Doctor</h3>
                    <div className="SelectTimeBox">
                      <aside>
                        <span className="Profile">
                          <img
                            className="image-avatar"
                            src={
                              filteredDoc[0]?.profile_pic
                                ? filteredDoc[0]?.profile_pic
                                : filteredDoc[0]?.gender?.toLowerCase() ===
                                  "male"
                                ? male
                                : female
                            }
                          />
                        </span>
                        <h3 className="text-capitalize">
                          {filteredDoc[0].firstname} {filteredDoc[0].lastname}
                        </h3>
                        <ul>
                          <li>
                            <span>
                              <img src="images/virtual-icon.png" />
                            </span>
                            Virtual
                          </li>
                          <li>
                            <span>
                              <img src="images/location-icon.png" />
                            </span>
                            Urgent
                          </li>
                        </ul>
                        <span className="RightArrow">
                          <i
                            className="fa fa-chevron-right"
                            aria-hidden="true"
                          />
                        </span>
                      </aside>
                    </div>
                    <div className="SelectTimeBox">
                      <p>
                        <span>
                          <img src="images/time-icon.png" />
                        </span>
                        {moment(selectedDate, "YYYY/MM/DD").format(
                          "dddd, hh:mm A z, MMM DD, YYYY"
                        )}{" "}
                        {selectedSlotStartTime}-{selectedSlotEndTime}
                      </p>
                    </div>
                    {appointmentFor == "patient" ? (
                      <>
                        <h3 className="BoxHeading">Patient</h3>
                        <div className="AppointmentPersonBox">
                          <aside>
                            <span className="Profile">
                              <img
                                className="image-avatar"
                                src={
                                  reduxUser?.profile_pic
                                    ? reduxUser?.profile_pic
                                    : reduxUser?.gender?.toLowerCase() ===
                                      "male"
                                    ? male
                                    : female
                                }
                              />
                            </span>
                            <h3 className="text-capitalize">
                              {reduxUser.firstname} {reduxUser.lastname}
                            </h3>
                            <ul>
                              <li>{calculateAge(reduxUser.dob)}</li>
                              <li>{reduxUser.gender}</li>
                            </ul>
                          </aside>
                        </div>
                      </>
                    ) : (
                      <>
                        <h3 className="BoxHeading">Patient</h3>
                        <div className="AppointmentPersonBox">
                          <aside>
                            <span className="Profile">
                              <img
                                className="image-avatar"
                                src={
                                  reduxUser?.profile_pic
                                    ? reduxUser?.profile_pic
                                    : reduxUser?.gender?.toLowerCase() ===
                                      "male"
                                    ? male
                                    : female
                                }
                              />
                            </span>
                            <h3 className="text-capitalize">
                              {appointmentFor == "patient" ? (
                                reduxUser.firstname
                              ) : (
                                <>
                                  {filteredFamily[0].firstname}{" "}
                                  {filteredFamily[0].lastname}
                                </>
                              )}
                            </h3>
                            <ul>
                              <li>{calculateAge(filteredFamily[0].dob)}</li>
                              <li>{filteredFamily[0].gender}</li>
                            </ul>
                          </aside>
                        </div>
                      </>
                    )}
                    <div className="ReasonBox">
                      <h3>Reason</h3>
                      <p>{reasonTovisit}</p>
                    </div>
                    <div className="ModalTable">
                      <table>
                        <tbody>
                          <tr>
                            <td>Consultant Fee</td>
                            <td>${filteredDoc[0].doctor_fees}</td>
                          </tr>
                          <tr>
                            <td>$Taxes</td>
                            <td>${additionalFee.gst}</td>
                            {/* <td>{additionalFee.platform_charge}</td>
                          <td>{additionalFee.service_charge}</td>
                          <td>{additionalFee.others}</td> */}
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Total</td>
                            <td>
                              {addFeeAndTax(
                                additionalFee.gst,
                                additionalFee.platform_charge,
                                additionalFee.service_charge,
                                additionalFee.others,
                                filteredDoc[0].doctor_fees
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className="ButtonBox ButtonBoxNew">
                      <button
                        className="Dark"
                        onClick={() => setbookingTabs(8)}
                      >
                        Confirm Payment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {bookingTabs == 5 ? (
            <>
              <div class="modal-body">
                <div class="BookingModalArea">
                  <h3 class="Title">
                    How would you like to <br />
                    pay for the meeting?
                  </h3>
                  <div class="UntilVisit">
                    {/* <p>You won’t be charged until your visit</p> */}
                  </div>
                  <div class="BookingClinicVisit">
                    <a
                      href="javascript:void(0);"
                      class="FolderPermissionId"
                      onClick={() => {
                        setvirtualPrimary(false);
                        settoggleInsurancePopup(true);
                      }}
                    >
                      <div class="CareBox">
                        <span class="Icon">
                          <img src="images/care-icon.png" />
                        </span>
                        <aside>
                          <h3>Use Insurance</h3>
                          <p>
                            $
                            {addFeeAndTax(
                              additionalFee?.gst,
                              additionalFee?.platform_charge,
                              additionalFee?.service_charge,
                              additionalFee?.others,
                              filteredDoc[0]?.doctor_fees
                            )}
                          </p>
                        </aside>
                        <span>
                          <i class="fa fa-chevron-right" aria-hidden="true"></i>
                        </span>
                      </div>
                    </a>
                    <a href="javascript:void(0);" class="FolderPermissionId">
                      <div class="CareBox">
                        <span class="Icon">
                          <img src="images/care-icon.png" />
                        </span>
                        <aside
                          onClick={() => {
                            const checkMembership = checkMembershipStatus();
                            if (checkMembership) {
                              setbookingTabs(8);
                            } else {
                              setbookingTabs(6);
                            }
                          }}
                        >
                          <h3>Pay Using Membership</h3>
                          <p>
                            $
                            {addFeeAndTax(
                              additionalFee?.gst,
                              additionalFee?.platform_charge,
                              additionalFee?.service_charge,
                              additionalFee?.others,
                              filteredDoc[0]?.doctor_fees
                            )}
                          </p>
                        </aside>
                        <span>
                          <i class="fa fa-chevron-right" aria-hidden="true"></i>
                        </span>
                      </div>
                    </a>
                    {/* <a
                      href="javascript:void(0);"
                      class="FolderPermissionId"
                      onClick={() => {
                        setvirtualPrimary(false);
                        settoggleCashPopup(true);
                      }}
                    >
                      <div class="CareBox">
                        <span class="Icon">
                          <img src="images/care-icon.png" />
                        </span>
                        <aside>
                          <h3>Pay Using Cash</h3>
                          <p>$00.00</p>
                        </aside>
                        <span>
                          <i class="fa fa-chevron-right" aria-hidden="true"></i>
                        </span>
                      </div>
                    </a> */}
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {bookingTabs == 6 ? (
            <>
              {/* <div className="modal-body">
                <div className="ModalTopArea">
                  <div className="BookingModalArea">
                    <h3 className="Title">
                      Purchase Membership <br />
                      Plan
                    </h3>
                    <div className="PayMonthly">
                      <aside>
                        <span>
                          <img src="images/pay-monthly.png" />
                        </span>
                        <h4>Pay Monthly</h4>
                        <h6>
                          $
                          {
                            membership[selectedMembership]
                              .membership_discount_cost
                          }{" "}
                          per month
                        </h6>
                      </aside>
                      <p>
                        ${membership[selectedMembership].membership_cost} today.
                        Then $
                        {
                          membership[selectedMembership]
                            .membership_discount_cost
                        }{" "}
                        per month for 12 months.
                      </p>
                    </div>
                    <div className="MembershipPlan">
                      <ul>
                        {console.log("memberships====>", membership)}
                        {membership.map((item, index) => (
                          <li
                            onClick={() => {
                              setselectedMembership(index);
                              storePlanData(
                                item?._id,
                                item?.membership_cost,
                                item?.relation_of_persons_with_user,
                                item.membership_discount_cost,
                                item.membership_name,
                                item.membership_description,
                                item.membership_duration,
                                item.membership_cost
                              );
                            }}
                          >
                            <input key={index} type="radio" name={`plan1`} />
                            <aside>
                              <span className="Icon">
                                <img src="images/plan-1.png" />
                              </span>
                              <h6>{item.membership_name}</h6>
                              <p>${item.membership_discount_cost} pm</p>
                              <span className="CheckOverlay">
                                <i className="fa fa-check" aria-hidden="true" />
                              </span>
                            </aside>
                          </li>
                        ))}
                      </ul>
                      <div className="ButtonBox ButtonBoxNew mt-3">
                        <button
                          className=""
                          onClick={() => {
                            if (selectedMembership >= 0) {
                              PurchasePlan();
                            } else {
                              toast("Please select a plan.");
                            }
                          }}
                        >
                          Confirm Payment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <MembershipComponent setbookingTabs={setbookingTabs} />
            </>
          ) : null}
          {bookingTabs == 7 ? (
            <>
              <div className="modal-body">
                <div className="LoginBox FolderPermissionId">
                  <div className="LoginHead">
                    <img src="images/Congratulations.png" />
                  </div>
                  <div className="AccountCreatBody">
                    <article>
                      <h3>Account Created</h3>
                      <h4>
                        Congratulations <br />
                        Your payment was successful
                      </h4>
                    </article>
                  </div>
                  <div className="PaymentSuccessTable">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <p>Transaction ID</p>
                            <h6>{planData.transaction_id}</h6>
                          </td>
                          <td>
                            <p>Plan Type</p>
                            <h6>{planData.membership_name}</h6>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Billing Month</p>
                            <h6>
                              ${planData.membership_discount_cost} Monthly
                            </h6>
                          </td>
                          <td>
                            <p>Purchase Date</p>
                            <h6>{planData.plan_start}</h6>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>Expiry Date</p>
                            <h6>
                              {calculateLastDate(
                                planData.plan_start,
                                planData.membership_duration
                              )}
                            </h6>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="Note">
                    <span>Note : </span> Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua.
                  </p>
                </div>
                <div className="ButtonBox ButtonBoxNew mt-3">
                  <button
                    className=""
                    onClick={() => {
                      setbookingTabs(8);
                    }}
                  >
                    Confirm Payment
                  </button>
                </div>
              </div>
            </>
          ) : null}

          {bookingTabs == 8 ? (
            <>
              <div className="modal-body">
                <div className="ModalTopArea">
                  <div className="LoginBox">
                    <div className="LoginHead">
                      <img src="images/Congratulations.png" />
                    </div>
                    <div className="AccountCreatBody">
                      <article>
                        <h3>Booking Confirmation</h3>
                      </article>
                    </div>
                    <div className="PaymentSuccessTable">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <p>Doctor</p>
                              <h6 className="text-capitalize">
                                {filteredDoc[0]?.firstname}
                              </h6>
                            </td>
                            <td>
                              <p>Appointment Type</p>
                              <h6>Virtual</h6>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>Billing Amount</p>
                              <h6>{filteredDoc[0]?.doctor_fees}</h6>
                            </td>
                            <td>
                              <p>Schedule Date</p>
                              <h6>{selectedDate}</h6>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p>Schedule Slot</p>
                              <h6>{selectedSlotStartTime}</h6>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p className="Note">
                      <span>Note : </span> Lorem ipsum dolor sit amet,
                      consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua.
                    </p>
                    <div className="ButtonBox ButtonBoxNew">
                      <button
                        className="Dark"
                        onClick={() => handleAppointmentBooking("membership")}
                      >
                        Proceed with Appointment Booking
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VirtualPrimary;
