import axiosInstance from "../axios/axios";

import { showNotification } from "../helper/helper";

export const patientService = {
  login: login,
  sendOtpOnPhone: sendOtp,
  createPatient: createPatient,
  updatePatient: updatePatient,
  updateFamilyMem: updateFamilyMem,
  getFamily: getFamily,
  insertProfileDetails: insertProfileDetails,
  saveInsuranceData: saveInsuranceData,
  addFamilyMem: addFamilyMem,
  getTaxes: getTaxes,
  getPlans: getPlans,
  getExtraData: getExtraData,
  getPatientById: getPatientById,
  getCustomerId: getCustomerId,
  getNotifications: getNotifications,
  sendContactForm: sendContactForm,
};

async function login(email) {
  try {
    const res = await axiosInstance.post("/api/patient/patient-login", {
      email: email,
      device_type: "website",
    });
    // console.log("==>", res);
    if (res.status == 200 && res.data.success == true) {
      localStorage.clear();
      return {
        email_verified: true,
        otp: res.data.otp,
        token: res.data.access_token,
        user: res.data.data,
        msg: res.data.message,
      };
    } else {
      return {
        email_verified: false,
        otp: res.data.otp,
        msg: res.data.message,
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function sendOtp(phone) {
  try {
    const res = await axiosInstance.post(
      "/api/patient/patient-otp-email-phone",
      {
        phone: phone,
      }
    );
    // console.log("==>", res);
    if (
      res.status == 200 &&
      res.data.success == true &&
      res.data.message ==
        "otp sent successfully on phone . Patient exists in database"
    ) {
      return {
        phone_registered: true,
        otp: res.data.otp,
      };
    } else {
      return { phone_registered: false, otp: res.data.otp };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function createPatient(email, phone) {
  try {
    const res = await axiosInstance.post("/api/patient/createPatient", {
      email: email,
      phone: phone,
      device_type: "website",
    });
    // console.log("==>", res);
    if (res.status == 201 && res.data.success == true) {
      return {
        is_created: true,
        patient: res.data.user,
        token: res.data.access_token,
      };
    } else {
      return { is_created: false };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function updatePatient(updateObj) {
  try {
    const res = await axiosInstance.put("/api/patient/upatePatient", updateObj);
    console.log("profile pic==>", res);
    if (res.status == 201 || res.status == 200) {
      return { status: true, data: res.data.data };
    } else {
      return { status: false, data: res.data.data };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function getFamily(userId) {
  try {
    const res = await axiosInstance.get(
      `/api/patient/get-family-members?patient_id=${userId}`
    );

    console.log("family api", res);
    console.log("family api family mem", res.data.data);

    if (res.status == 201 || res.status == 200) {
      return {
        family_found: true,
        family: res.data.data,
      };
    } else {
      console.log("==>res", res);
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function updateFamilyMem(updateObj, params) {
  try {
    const res = await axiosInstance.put(
      `/api/patient/update-family-member/${params}`,
      updateObj
    );
    console.log("updatedFam==>", res);
    if (res.status == 201 || res.status == 200) {
      return { status: true, data: res.data.data };
    } else {
      return { status: false, data: res.data.data };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function insertProfileDetails(data) {
  try {
    const res = await axiosInstance.put("/api/patient/upatePatient", data);
    console.log("==>profile details", res);
    const user = res.data.data;
    if (res.status == 200 && res.data.success == true) {
      localStorage.removeItem("user");

      localStorage.setItem("user", JSON.stringify(user));

      return { success: true, data: user };
    } else {
      return { success: false, data: user };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function saveInsuranceData(data) {
  try {
    const res = await axiosInstance.post(
      "/api/insurance/createInsurance",
      data
    );
    // console.log("==>", res);
    if (res.status == 200 || (res.status == 201 && res.data.success == true)) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function addFamilyMem(data) {
  try {
    const res = await axiosInstance.post(
      "/api/patient/add-family-members",
      data
    );
    console.log("==>", res);
    if (res.status == 201) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function sendContactForm(data) {
  try {
    const { name, email, phone, message } = data;
    const res = await axiosInstance.get(
      `/api/patient/contact-form?name=${name}&email=${email}&phone=${phone}&message=${message}`
    );
    console.log("==>", res);
    if (res.status == 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function getTaxes(data) {
  try {
    const res = await axiosInstance.get(
      "/api/additional-fees/get-additional-fees"
    );
    console.log("==>additional fee", res);
    if (res.status == 200 || res.status == 200) {
      return res;
    } else {
      return res;
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function getPlans() {
  try {
    const res = await axiosInstance.get("/api/membership/get-membership");
    console.log("==>additional fee", res);
    if (res.status == 200 || res.status == 200) {
      return res;
    } else {
      return res;
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function getExtraData(userId) {
  try {
    const res = await axiosInstance.get(
      `/api/patient/get-extra-details/${userId}`
    );

    console.log("extra data", res);

    if (res.status == 201 || res.status == 200) {
      return {
        success: true,
        data: res.data.data,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function getPatientById(id) {
  try {
    const res = await axiosInstance.get(`/api/patient/getPatient/${id}`);

    if (res.status == 201 || res.status == 200) {
      return {
        success: true,
        data: res.data.data,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function getCustomerId(email, phone, name) {
  try {
    const res = await axiosInstance.post(`/api/stripe/create-stripe-customer`, {
      email,
      phone,
      name,
    });

    console.log("CREATE CUSTOMER", res);

    if (res.status == 201 || res.status == 200) {
      return {
        success: true,
        data: res.data.customer_id,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (err) {
    console.log(err.message);
  }
}

async function getNotifications(id) {
  try {
    const res = await axiosInstance.get(
      `/api/admin/get-notifications-by-patientId?patient_id=${id}`
    );

    // console.log("notifications===>", res);
    if (res.data.success) {
      return {
        success: true,
        data: res.data.notification,
      };
    } else {
      return {
        success: false,
        data: [],
      };
    }
  } catch (err) {
    console.log(err.message);
    return { success: false, data: "" };
  }
}
